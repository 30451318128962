import './App.css';
import React, { useState } from 'react';
import { easeIn, easeInOut, motion } from "framer-motion"

function App() {

  const [scrollFinished, setScrollFinished] = useState(false);
  const imageCount = 5; // Number of images
  const imageHeight = 200; // Height of each image in pixels
  const handleAnimationComplete = () => {
    setScrollFinished(true);
  };

  return (
    <div className="main">
      <div class="wrapper">
        <h1>coming soon<span class="dot">.</span></h1>
      </div>
      <div className='bg'>
      <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : -3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/1.png')} alt='imagez' />
          <img src={require('./assets/2.png')} alt='imagez' />
          <img src={require('./assets/3.png')} alt='imagez' />
          <img src={require('./assets/4.png')} alt='imagez' />
          <img src={require('./assets/5.png')} alt='imagez' />
        </motion.div>
        <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : 3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding2'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/6.png')} alt='imagez' />
          <img src={require('./assets/7.png')} alt='imagez' />
          <img src={require('./assets/8.png')} alt='imagez' />
          <img src={require('./assets/9.png')} alt='imagez' />
          <img src={require('./assets/10.png')} alt='imagez' />
        </motion.div>
      <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : -3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/11.png')} alt='imagez' />
          <img src={require('./assets/12.png')} alt='imagez' />
          <img src={require('./assets/13.png')} alt='imagez' />
          <img src={require('./assets/14.png')} alt='imagez' />
          <img src={require('./assets/15.png')} alt='imagez' />
        </motion.div>
        <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : 3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding2'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/16.png')} alt='imagez' />
          <img src={require('./assets/17.png')} alt='imagez' />
          <img src={require('./assets/1.png')} alt='imagez' />
          <img src={require('./assets/2.png')} alt='imagez' />
          <img src={require('./assets/3.png')} alt='imagez' />
        </motion.div>
      <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : -3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/4.png')} alt='imagez' />
          <img src={require('./assets/5.png')} alt='imagez' />
          <img src={require('./assets/6.png')} alt='imagez' />
          <img src={require('./assets/7.png')} alt='imagez' />
          <img src={require('./assets/8.png')} alt='imagez' />
        </motion.div>
        <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : 3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding2'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/9.png')} alt='imagez' />
          <img src={require('./assets/10.png')} alt='imagez' />
          <img src={require('./assets/11.png')} alt='imagez' />
          <img src={require('./assets/12.png')} alt='imagez' />
          <img src={require('./assets/13.png')} alt='imagez' />
        </motion.div>
      <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : -3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/14.png')} alt='imagez' />
          <img src={require('./assets/15.png')} alt='imagez' />
          <img src={require('./assets/16.png')} alt='imagez' />
          <img src={require('./assets/1.png')} alt='imagez' />
          <img src={require('./assets/2.png')} alt='imagez' />
        </motion.div>
        <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : 3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding2'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/3.png')} alt='imagez' />
          <img src={require('./assets/4.png')} alt='imagez' />
          <img src={require('./assets/5.png')} alt='imagez' />
          <img src={require('./assets/6.png')} alt='imagez' />
          <img src={require('./assets/7.png')} alt='imagez' />
        </motion.div>
      <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : -3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/8.png')} alt='imagez' />
          <img src={require('./assets/9.png')} alt='imagez' />
          <img src={require('./assets/10.png')} alt='imagez' />
          <img src={require('./assets/11.png')} alt='imagez' />
          <img src={require('./assets/12.png')} alt='imagez' />
        </motion.div>
        <motion.div
          animate={{ initial: 0, translateY: scrollFinished ? 0 : 3000 }}
          transition={{ repeat: Infinity, duration: 250, type: 'easeIn' }}
          className='images-sliding2'
          onAnimationComplete={handleAnimationComplete}
        >
          <img src={require('./assets/13.png')} alt='imagez' />
          <img src={require('./assets/14.png')} alt='imagez' />
          <img src={require('./assets/15.png')} alt='imagez' />
          <img src={require('./assets/16.png')} alt='imagez' />
          <img src={require('./assets/17.png')} alt='imagez' />
        </motion.div>
      </div>

    </div>
  );
}

export default App;
